import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import Layout from '../../../../components/layout';
function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for registering to attend GMIS2021 which will be held
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            at EXPO's Dubai Exhibition Centre from November 22-27, 2021.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography align='center' variant='body1' gutterBottom>
            Please note that your registration is under process and you should be receiving an email to confirm the same
            shortly, if not, please check the spam folder or email us on{' '}
            <Link href='mailto:registration@gmisummit.com'> registration@gmisummit.com.</Link>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            To complete your registration, please proceed to the Expo 2020 Dubai Media Information System and register
            for{' '}
            <Link href='https://media.expo2020dubai.com' target='_blank'>
              Tawassul
            </Link>
            .
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Once approved via Tawassul, please submit a Media Accreditation Expression of Interest. Expo media
            accreditation will permit media access to the Expo 2020 site and Expo 2020 media services and facilities
            while attending GMIS.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
